import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import '../../Modal/modal.css';
import PearsonLogo from '../../../../images/pearson_logo.png';
import { addSmsInfo, deleteSmsInfo } from './actions';


const SmsPopup = ({isOpen, onRequestClose, currentPath, smsFile}) => {

	const [smsInfo, setSmsInfo] = useState({});
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const smsStateInfo = useSelector(state => { return state.directoriesReducer.smsInfo });
	
	useEffect(() => {
		
		if(isOpen  && smsStateInfo !== null)
		{
			setSmsInfo(smsStateInfo[0]);
		}

	}, [smsStateInfo, isOpen])

	const onModalpopupClose = (e) =>{
		if (searchParams.has('sms_config')) {
            searchParams.delete('sms_config');
            setSearchParams(searchParams);
        }
		onRequestClose(isOpen);
	}

	const handleSubmit = (e) =>{
		e.preventDefault();
		if(smsInfo.smssite === "" || smsInfo.smsmod === "" || smsInfo.smssite === undefined || smsInfo.smsmod === undefined)
		{
			alert('Please Fill in all required fields');
			return;
		}
		if(smsInfo.role === undefined)
		{
			let role = document.getElementsByName('role')[0].value;
			smsInfo.role = role;
		}
		if(smsInfo.enable === undefined)
		{
			let enable = document.getElementsByName('enable')[0].value;
			smsInfo.enable = enable;
		}
		let smsInfoPayload = Object.assign({...smsInfo, sms_config: true, current_path: currentPath, sms_file: smsFile, sms_command: 'save'})
		delete smsInfoPayload._id;
		delete smsInfoPayload.enable_dir_sms_protection;
		delete smsInfoPayload.smsFilePath;
		dispatch(addSmsInfo({smsInfoPayload}));
		onModalpopupClose();
	} 

	const onChangeSMSfield = (e) => {
		setSmsInfo(Object.assign
			({
				...smsInfo,
				[e.target.name] : e.target.value
		    })
		)
	}

	const deleteSmsConfig = (e) => {
		let deleteSmsConfigObj = { current_path: currentPath, sms_file: smsFile, sms_command: 'delete', uniqueId: smsInfo._id }
		dispatch(deleteSmsInfo({ deleteSmsConfigObj }));
		onModalpopupClose();
	}

    return(
        <div className="modal-content-table" >     
           	<table border="0" width="99%" cellSpacing="3" cellPadding="0" >
	            <tr align="left" valign="top">
	            	<td align="left" valign="middle"><span className="pagetitle"> SMS Configuration</span></td>
	            	<td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="85" height="28" hspace="0" vspace="0" border="0"/></td>
                    <td><span 
                            className="close"
                             onClick={onModalpopupClose}>&times;
                        </span>
                    </td>
	            </tr>
           	</table>
			<br/>
           	<table border="0" cellSpacing="0" cellPadding="0">
                <tr align="left" valign="top">
		            <td width="90%" valign="top" className="crumb"><strong>Current Location: </strong> 
						{decodeURIComponent(currentPath)}
					</td>
				</tr>
				{(smsInfo?.enable_dir_sms_protection === 'Enabled') &&
					<tr>
						<td width="90%" valign="top" className="crumb"><strong>This object inherits protection from: </strong>{smsInfo?.smsFilePath}</td>
					</tr>
				}
				{(smsInfo?.enable_dir_sms_protection === 'Enabled') &&
					
					<tr>
						<td><font size="-1" color="red">If you wish to override those settings, enter SMS configuration options below</font></td>
					</tr>
				}
			</table>
			<br/>

            <table width="99%" border="0" cellSpacing="0" cellPadding="0"   className='smsTable'>
                <tr align="left" valign="top">
	                <td valign="top" className="lightFrameColor">
	
	
		                <table width="100%" border="0" cellSpacing="0" cellPadding="0"  className='smsTable'>
		                    <tr align="left" valign="top">
			                    <td valign="top">
									<form name="smsconfig" onSubmit={handleSubmit} method={'POST'}>
										<table width="100%" border="0" cellPadding="4" cellSpacing="1">
											<tr valign="top">
												<td colSpan="3" className="tableTitleBg">
													<p>
														<span className="tableTitle">Enter SMS configuration options here.</span><br/>
														<span className="alert">* Indicates required information</span><br/>
													</p>
												</td>
											</tr>
											<tr>
												
												{/* <input type=hidden name=sms_config value=1>
												<input type=hidden name=sms_file value='<?= basename($sms_file) ?>' >
												<input type=hidden name=sms_command value="save"> */}
												<td align="left" valign="middle" nowrap="true" className="tableContentBg">
													<span className="alert"><strong>*</strong></span>
													<strong className="tableContent">SMS Site ID</strong>
												</td>
												<td colSpan="2" className="tableContentBg">
													<p>
													<input type="text" name="smssite" className="formSMSTextFull" maxLength="9" 
													value={smsInfo?.smssite || ''} onChange={(e) => onChangeSMSfield(e)}/>
													</p>
												</td>
											</tr>
											<tr>
												<td align="left" valign="middle" nowrap="true" className="tableContentBg">
													<span className="alert"><strong>*</strong></span>
													<strong className="tableContent">SMS Module ID</strong></td>
												<td colSpan="2" className="tableContentBg">
													<p>
													<input type="text" name="smsmod" className="formSMSTextFull" maxLength="9" 
													value={smsInfo?.smsmod  || ''} onChange={(e) => onChangeSMSfield(e)}/>
													</p>
												</td>
											</tr>
											<tr>
												<td align="left" valign="middle" nowrap="true" className="tableContentBg">
													<span className="alert"><strong>*</strong></span>
													<strong className="tableContent">Role</strong>
												</td>
												<td colSpan="2" className="tableContentBg">
													<p>
													<select name="role" className="formDropdown" onChange={(e) => onChangeSMSfield(e)}>
														<option value="student" selected = {smsInfo?.role === 'student' ? 'selected': ''}>
															Student
														</option>
														<option value="educator" selected = {smsInfo?.role === 'educator' ? 'selected': ''}>
															Educator
														</option>
													</select>
													</p>
												</td>
											</tr>
											<tr>
												<td align="left" valign="middle" nowrap="true" className="tableContent">
													<strong>Protection</strong>
												</td>
												<td colSpan="2" valign="top" className="tableContentBg">
													<table width="100%" border="0" cellSpacing="0" cellPadding="1">
														<tr>
															<td nowrap="true" className="tableContent">
																<input type="radio" name="enable" className="formRadio" value="Enabled" checked = {smsInfo?.enable === 'Enabled' ? 'checked' : (smsInfo?.enable === undefined) ? 'checked' : '' } onChange={(e) => onChangeSMSfield(e)}/>&nbsp;
															</td>
															<td width="99%" valign="middle" className="tableContent" >Enable &nbsp;&nbsp;&nbsp;
																<input type="radio" name="enable" className="formRadio" value="Disabled" checked = {smsInfo?.enable === 'Disabled' ? 'checked' : ''} onChange={(e) => onChangeSMSfield(e)}/>&nbsp;Disable
															</td>
														</tr>
													</table>
												</td>
											</tr>
											<tr className="tableContentBg">
												<td valign="top" nowrap="true" className="tableContentTop">
													<strong>Comments/Notes</strong>
												</td>
												<td colSpan="2">
													<textarea name="comment" rows="5" className="formSMSTextFull" wrap="soft" value={smsInfo?.comment} onChange={(e) => onChangeSMSfield(e)}></textarea>
												</td>
											</tr>

											<tr>
												<td align="center" className="tableContentBg" colSpan='3'>
													<p>
													<input type="button" name="cancel_button" value="&nbsp;&nbsp;Cancel&nbsp;&nbsp;"   className="formButtonStop" onClick={onModalpopupClose}/>
													&nbsp;&nbsp;
													{smsInfo?._id &&
														<input type="button" name="delete_button" value="&nbsp;&nbsp;Remove SMS Protection&nbsp;&nbsp;"  className="formButtonStop" onClick={deleteSmsConfig}/>
													}
													&nbsp;&nbsp;
													<input type="submit" name="save_button" value="&nbsp;&nbsp;Save Changes&nbsp;&nbsp;"  className="formSMSButtonGo"/>
													</p>
												</td>
											</tr>
										</table>
									</form>
								</td>
            				</tr>
	  					</table>
					</td>
  				</tr>
			</table>
			<table width="100%">
				<tr>
					<td align="left" className="copyright">Copyright &copy; 2004 Pearson Education</td>
				</tr>
			</table>
		</div>
	)
}

export default SmsPopup